/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  useToast,
  Box,
  Heading,
  HStack,
  IconButton,
  Flex,
  useDisclosure,
  Stack,
  Text,
  Skeleton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { Popconfirm } from 'antd'
import moment from 'moment'

import SpinnerOverlay from 'components/SpinnerOverlay'
import HorizonTable from 'components/Horizons/table'
import UpdateCourseModal from './Modals/UpdateCourse'
import ReScheduleModal from './Modals/ReSchedule'
import ClearCourseModal from './Modals/ClearCourse'

import useKid from 'hooks/useKid'
import useSchedule from 'hooks/useSchedule'
import useBranch from 'hooks/useBranch'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'config/firebase'
import { useCollection, useDocument } from '@lemasc/swr-firestore'
import {
  where as whereCollection,
  orderBy as whereOrderBy,
} from '@lemasc/swr-firestore/constraints'

export default function UpdateCourse({ managerBranchId, user, role }) {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const toast = useToast()
  const { id, program } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenRe,
    onOpen: onOpenRe,
    onClose: onCloseRe,
  } = useDisclosure()
  const {
    isOpen: isOpenReSult,
    onOpen: onOpenReSult,
    onClose: onCloseReSult,
  } = useDisclosure()
  const {
    isOpen: isOpenClear,
    onOpen: onOpenClear,
    onClose: onCloseClear,
  } = useDisclosure()

  const [result, setResult] = useState()

  const [editData, setEdit] = useState(null)

  const { getBranch, branches } = useBranch()
  const { kid } = useKid(id)
  const [programData, setProgram] = useState([])
  const { removeSchedule, unScheduleById, unSchedule } = useSchedule({
    kidId: id,
    programId: program,
  })

  const [branchName, setBranch] = useState('')
  const [showCanceled, setShowCanceled] = useState(false)

  const { data: kidProgram } = useDocument(
    id && program ? `Kids/${id}/Programs/${program}` : null,
    {
      listen: false,
    }
  )
  const { data } = useCollection(
    kid?.branchId ? 'Profiles' : null,
    {
      constraints: [
        whereCollection('role', '==', 'Staff'),
        whereCollection('branchId', '==', kid?.branchId),
        whereOrderBy('createdAt', 'desc'),
      ],
    },
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 0,
    }
  )
  const staffs = useMemo(() => {
    return data?.filter((a) => !a.disabled)
  }, [data, programData])

  const branchProvince = useMemo(() => {
    const findBranch = branches.find((b) => b.id === kid?.branchId)
    return findBranch?.province ? findBranch?.province : 'all'
  }, [kid, branches])

  const limitDate = useMemo(() => {
    const firstSchedule = programData
      .filter((schedule) => schedule?.date !== '')
      .sort((a, b) => new Date(a.date) - new Date(b.date))[0]

    return kidProgram?.limit
      ? firstSchedule &&
          moment(firstSchedule?.date)
            .add(kidProgram?.limit, 'M')
            .format('DD/MM/YYYY')
      : ''
  }, [programData, kidProgram?.limit])

  useEffect(() => {
    if (program) {
      setLoading(true)

      const queryprogramDoc = query(
        collection(db, `Schedules`),
        where('programId', '==', program)
      )
      const unsubscribe = onSnapshot(queryprogramDoc, (snapShot) => {
        const data = snapShot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id }
        })

        setProgram(data)
        setLoadingData(false)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [program])

  const scheduleProgram = useMemo(() => {
    if (showCanceled) {
      const dataS = programData
        .filter((p) => p.cancelLog)
        .sort((a, b) => Number(a.timeStart) - Number(b.timeStart))
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((data, index) => {
          return { ...data, index }
        })
      const sortProgram = [...dataS].map((data, index) => {
        return { ...data, index }
      })
      return sortProgram
    } else {
      const isClear = programData.filter((p) => p.isClear)
      const invDate = programData
        .filter((p) => !p.isClear)
        .filter((p) => !p.cancelLog)
        .filter((a) => a.date === '' || a.date === 'Invalid date')
      const dataS = programData
        .filter((p) => !p.cancelLog)
        .filter((p) => !p.isClear)
        .filter((a) => a.date !== '' && a.date !== 'Invalid date')
        .sort((a, b) => Number(a.timeStart) - Number(b.timeStart))
        .sort((a, b) => new Date(a.date) - new Date(b.date))

      const sortProgram = [...dataS, ...invDate, ...isClear].map(
        (data, index) => {
          return { ...data, index }
        }
      )
      return sortProgram
    }
  }, [programData, showCanceled])

  useEffect(() => {
    if (kid?.branchId) {
      fetchBranch(kid?.branchId)
    }
  }, [kid?.branchId])

  const selectedCourse = useMemo(() => {
    console.log(programData, 'programData')
    if (!programData.length) return null
    return {
      value: programData[0].program,
      label:
        `${programData[0]?.program?.courseName} ${programData[0]?.program?.choice} (${programData[0]?.program?.amount} ${programData[0]?.program?.unit})` ||
        programData[0].program?.label,
    }
  }, [programData])

  const completedAmount = useMemo(() => {
    return (
      programData?.reduce((acc, val) => {
        return val.isTaked ? acc + 1 : acc
      }, 0) || 0
    )
  }, [programData])

  const clearLeft = useMemo(() => {
    return programData
      .filter((p) => !p.cancelLog)
      .filter((a) => !a.isClear)
      .filter((a) => !a.isTaked).length
  }, [programData])

  const reScheduleAmount = useMemo(() => {
    const clearAmount = programData
      .filter((p) => !p.cancelLog)
      .filter((a) => a.isClear).length
    return (
      Number(
        selectedCourse?.value?.amount
          ? selectedCourse?.value?.amount
          : kidProgram?.amount
          ? kidProgram?.amount
          : 0
      ) -
      completedAmount -
      clearAmount
    )
  }, [selectedCourse, programData])

  async function fetchBranch(id) {
    const res = await getBranch(id)
    setBranch(res.name)
  }

  async function removeScheduleDate(row) {
    setLoading(true)
    try {
      const res = await removeSchedule({
        id: row.id,
        kidId: id,
        kid: kid,
        programId: row.programId,
        programName: `${row?.program.courseName} ${row?.program.choice} (${row?.program.amount} ${row?.program.unit})`,
        staffId: row.staffId,
        staff: row.staff,
        schedule: {
          date: row.date,
          staffId: row.staffId,
          staff: row.staff,
          timeEnd: row.timeEnd,
          timeStart: row.timeStart,
        },
      })
      if (!res.data.isAvailable) {
        setResult(res.data)
        onOpenReSult()
      } else {
        toast({
          position: 'top',
          title: 'Update Schedule',
          description: 'Schedule has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function unScheduleDate(row) {
    setLoading(true)
    try {
      await unScheduleById({
        scheduleId: row.id,
      })

      toast({
        position: 'top',
        title: 'Update Schedule',
        description: 'Schedule has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function unScheduleAll() {
    setLoading(true)
    try {
      await unSchedule({
        programId: program,
      })

      toast({
        position: 'top',
        title: 'Update Schedule',
        description: 'Schedule has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ครังที่',
        accessor: 'index',
        key: 'index',
        width: '10%',
        extra: (data) => data + 1,
      },

      {
        Header: 'วันทืี่',
        accessor: 'date',
        key: 'date',
        width: '20%',
        extra: (data, row) => (
          <Text
            textDecoration={row.cancelLog ? 'line-through' : '-moz-initial'}
          >
            {data && data !== ''
              ? `${new Date(data).toLocaleDateString('th-TH', {
                  weekday: 'long',
                })} ${moment(data).format('DD/MM/YYYY')}`
              : row.cancelLog?.date
              ? `${new Date(row.cancelLog?.date).toLocaleDateString('th-TH', {
                  weekday: 'long',
                })} ${moment(row.cancelLog?.date).format('DD/MM/YYYY')}`
              : '-'}
          </Text>
        ),
      },
      {
        Header: 'ครูผู้สอน',
        accessor: 'staff',
        key: 'staff',
        width: '20%',
        extra: (staff, row) => {
          return (
            <Text
              textDecoration={row.cancelLog ? 'line-through' : '-moz-initial'}
            >
              {staff
                ? `${staff?.nickname} (${staff?.fullname})`
                : row?.cancelLog
                ? `${row?.cancelLog?.staff?.nickname} (${row?.cancelLog?.staff?.fullname})`
                : '-'}
            </Text>
          )
        },
      },
      {
        Header: 'เวลาเริ่ม',
        accessor: 'timeStart',
        key: 'timeStart',
        width: '10%',
        extra: (data, row) => {
          return (
            <Text
              textDecoration={row.cancelLog ? 'line-through' : '-moz-initial'}
            >
              {data || row?.cancelLog?.timeStart || '-'}
            </Text>
          )
        },
      },
      {
        Header: 'เวลาเลิก',
        accessor: 'timeEnd',
        key: 'timeEnd',
        width: '10%',
        extra: (data, row) => {
          return (
            <Text
              textDecoration={row.cancelLog ? 'line-through' : '-moz-initial'}
            >
              {data || row?.cancelLog?.timeEnd || '-'}
            </Text>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'isTaked',
        key: 'isTaked',
        width: '12%',
        extra: (data, row) => {
          return row?.isClear ? (
            <Text color='gray'>Clear</Text>
          ) : (
            <Text
              color={
                row?.cancelLog || row?.timeStart === ''
                  ? 'grey'
                  : data
                  ? 'brand'
                  : 'red'
              }
            >
              {row?.cancelLog
                ? 'Canceled'
                : row?.timeStart === ''
                ? 'Unschedule'
                : data
                ? 'Completed'
                : 'Not Complete'}
            </Text>
          )
        },
      },
      {
        Header: 'Action',
        accessor: 'Action',
        key: 'Action',
        width: '10%',
        extra: (data, row) => {
          return (
            !row?.cancelLog &&
            !row?.isTaked &&
            !row?.isClear && (
              <HStack m={0} p={0}>
                <IconButton
                  size='sm'
                  variant='ghost'
                  aria-label='edit'
                  icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                  onClick={() => {
                    setEdit({ ...row, program: programData[0]?.program })
                    onOpen()
                  }}
                />

                {row?.date !== '' && (
                  <>
                    {programData[0]?.program?.amount > 1 && (
                      <Popconfirm
                        title='Sure to cancel this Schedule'
                        onConfirm={() => {
                          removeScheduleDate(row)
                        }}
                      >
                        <IconButton
                          size='sm'
                          variant='ghost'
                          aria-label='delete'
                          icon={<AiFillDelete color='red' fontSize='25px' />}
                        />
                      </Popconfirm>
                    )}
                    <Popconfirm
                      title='Sure to Remove from Schedule'
                      onConfirm={() => {
                        unScheduleDate(row)
                      }}
                    >
                      <Button
                        size='sm'
                        variant='outline'
                        colorScheme='gray'
                        aria-label='delete'
                      >
                        Unschedule
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </HStack>
            )
          )
        },
      },
    ],
    [programData]
  )

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Modal
        isOpen={isOpenReSult}
        onClose={onCloseReSult}
        size={{ base: '4xl', lg: '4xl' }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={10}>
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Time</Th>
                    <Th>Staff</Th>
                    <Th>Kid</Th>
                    <Th>Result</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr spacing={10}>
                    <Td>
                      {`${new Date(result?.date).toLocaleDateString('th-TH', {
                        weekday: 'long',
                      })} ${moment(result?.date).format('DD/MM/YYYY')}`}
                    </Td>
                    <Td>{`${result?.timeStart}-${result?.timeEnd} `}</Td>
                    <Td>{`${result?.staff?.nickname} (${result?.staff?.fullname})`}</Td>
                    <Td>{`${kid?.nickname} (${kid?.code})`}</Td>
                    <Td color={result?.isAvailable ? 'green' : 'red'}>
                      {result?.message}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isOpen && (
        <UpdateCourseModal
          user={user}
          isOpen={isOpen}
          onClose={() => {
            setEdit(null)
            onClose()
          }}
          data={editData}
          staffs={staffs}
          kidId={id}
          kidBranchId={kid?.branchId}
          selectedCourse={selectedCourse}
          courseLabel={`${programData[0]?.program?.courseName} ${programData[0]?.program?.choice} (${programData[0]?.program?.amount} ${programData[0]?.program?.unit})`}
        />
      )}
      {isOpenRe && (
        <ReScheduleModal
          user={user}
          isOpen={isOpenRe}
          onClose={onCloseRe}
          kidId={id}
          kidStatus={kid?.status}
          kid={kid}
          managerBranchId={managerBranchId}
          selectedCourse={selectedCourse}
          leftAmount={reScheduleAmount}
          programId={program}
          branchProvince={branchProvince}
        />
      )}
      {isOpenClear && (
        <ClearCourseModal
          user={user}
          isOpen={isOpenClear}
          onClose={onCloseClear}
          kidId={id}
          kidStatus={kid?.status}
          kid={kid}
          managerBranchId={managerBranchId}
          selectedCourse={selectedCourse}
          clearLeft={clearLeft}
          leftAmount={
            Number(selectedCourse?.value?.amount ?? kidProgram?.amount) -
            completedAmount
          }
          completedAmount={completedAmount}
          programId={program}
          branchProvince={branchProvince}
        />
      )}
      <SpinnerOverlay loading={isLoading} />
      <Flex justifyContent='space-between'>
        <IconButton
          onClick={() => navigate(`/kids/${id}?tab=3`)}
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
        />
        {kid && (
          <Skeleton isLoaded={!isLoadingData} fadeDuration={1}>
            <HStack spacing={2}>
              {branchName !== '' && (
                <HStack spacing={1}>
                  <Box
                    fontWeight='semibold'
                    as='h6'
                    lineHeight='tight'
                    fontSize='md'
                    display={{ base: 'none', md: 'block' }}
                  >
                    OFFICE :
                  </Box>
                  <Box fontSize='md'>{branchName}</Box>
                </HStack>
              )}
              {kid?.code && (
                <HStack spacing={1} fontSize='md'>
                  <Box
                    fontWeight='semibold'
                    as='h5'
                    lineHeight='tight'
                    display={{ base: 'none', md: 'block' }}
                  >
                    KID CODE :
                  </Box>
                  <Text>{`${kid?.code}`}</Text>
                </HStack>
              )}
              <HStack spacing={1} fontSize='md'>
                <Box
                  fontWeight='semibold'
                  as='h5'
                  lineHeight='tight'
                  display={{ base: 'none', md: 'block' }}
                >
                  KID'S NAME :
                </Box>
                <Text fontSize='md'>{`${kid?.name || ''} (${
                  kid?.nickname
                })`}</Text>
              </HStack>
            </HStack>
          </Skeleton>
        )}
        <Box />
      </Flex>
      <HStack justifyContent='space-between' alignItems='baseline'>
        <Skeleton isLoaded={!isLoadingData} fadeDuration={1} my={10}>
          {kidProgram && (
            <Stack>
              <Heading size='md'>
                {`${kidProgram?.courseName || ''} / ${
                  kidProgram?.choice || ''
                } `}
                <span style={{ color: '#01AEF0' }}>{` (${completedAmount} /${
                  kidProgram?.amount || ''
                })`}</span>
              </Heading>
              {limitDate && (
                <HStack spacing={4}>
                  <Heading size='sm'>Date Limit </Heading>
                  <Text fontSize='md'>{limitDate}</Text>
                </HStack>
              )}
            </Stack>
          )}
        </Skeleton>
        <HStack>
          {role === 'Owner' && clearLeft > 0 && (
            <Button
              m={2}
              onClick={() => onOpenClear()}
              variant='outline'
              colorScheme='cyan'
            >
              Clear AP
            </Button>
          )}
          <Popconfirm
            title='Sure to Unschedule All Uncompleted Program'
            onConfirm={() => {
              unScheduleAll()
            }}
          >
            <Button m={2} variant='outline' colorScheme='black'>
              UnSchedule All
            </Button>
          </Popconfirm>
          <Button
            m={2}
            onClick={() => onOpenRe()}
            variant='outline'
            colorScheme='lime'
          >
            Re Schedule
          </Button>
          <Button
            m={2}
            onClick={() => setShowCanceled(!showCanceled)}
            variant='solid'
            colorScheme={'orange'}
          >
            {`${showCanceled ? 'Hide' : 'Show'} Canceled Schedule`}
          </Button>
        </HStack>
      </HStack>
      <Stack>
        <HorizonTable
          columnsData={columns}
          tableData={scheduleProgram}
          loading={isLoadingData}
          noSearch
          useCurrentPage
        />
      </Stack>
    </Box>
  )
}
