/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useCheckboxGroup,
  CheckboxGroup,
  Checkbox,
  Switch,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Stack,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import moment from 'moment'
import { db } from 'config/firebase'
import {
  writeBatch,
  doc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import FormInputs from 'components/DynamicForm'
import useSchedule from 'hooks/useSchedule'
import useKid from 'hooks/useKid'
import { Link } from 'react-router-dom'

const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => {
  return (
    <Box {...groupStyles}>
      <Text fontSize='lg'>{data.name}</Text>
      <span style={groupBadgeStyles}>{data.programs.length}</span>
    </Box>
  )
}

export default function TakeCourseModal({
  isOpen,
  onClose,
  event,
  courses,
  date,
  allStaff,
  // managerBranchId,
  selectedBranch,
  role,
  fullscreen,
  user,
  branchProvince,
}) {
  const { staff } = event
  const { getAllKidsNoOff, removeProgramMaynyKid } = useKid()
  const [coursesStaff] = useState(courses)
  const [staffs, setStaff] = useState(allStaff)
  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure()
  const {
    updateSchedule,
    createSchedule,
    checkUpdateAvailable,
    deleteSchedule,
    getCompletedSchedule,
    getCompletedScheduleManyKids,
    unScheduleById,
  } = useSchedule({})
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  })
  const [kids, setKids] = useState([])
  const [allKid, setAllKid] = useState(kids)
  const [staffWorkingDays, setStaffWorkingDays] = useState([])
  const [courseOptions, setCourseOption] = useState([])
  const [completed, setCompleted] = useState()
  const [completedManyKids, setCompletedManyKids] = useState()

  const {
    value: checkboxValue,
    setValue: setCheckboxValue,
    getCheckboxProps,
  } = useCheckboxGroup({
    defaultValue: [],
  })

  const [result, setResult] = useState()

  const selectedCourse = watch('course')
  const startTime = watch('timeStart')
  const selectedKid = watch('kid')
  const selectedStaff = watch('staffId')
  const selectedDate = watch('date')
  useEffect(() => {
    fetchKids()
    setCourseOption(coursesStaff)
  }, [])

  const fetchKids = async () => {
    const res = await getAllKidsNoOff(selectedBranch)

    setKids(res.filter((kid) => !kid.isArchive))
    if (typeof event?.programId === 'string') {
      const res2 = await getCompletedSchedule(
        event.programId,
        event.date,
        event.startTime
      )
      setCompleted(res2)
    } else if (event?.programId) {
      const res2 = await getCompletedScheduleManyKids(
        event.programId,
        event.date,
        event.startTime
      )
      setCompletedManyKids(res2)
    }
  }

  useEffect(() => {
    if (selectedStaff && selectedStaff !== '') {
      const staff = staffs.find((item) => item.id === selectedStaff)
      const workingDay = staff?.workingDay || []
      const workingDayWeekend = staff?.workingDayWeekend || []
      setStaffWorkingDays(workingDay.concat(workingDayWeekend))
    } else {
      setStaffWorkingDays([])
    }
  }, [selectedStaff])

  useEffect(() => {
    if (selectedCourse) {
      const type = selectedCourse?.value?.type || selectedCourse?.type
      setCheckboxValue([moment(selectedDate).day().toString()])

      if (type !== 'Evaluation') {
        setAllKid(
          kids
            .filter((kid) => {
              return kid?.status === 'member'
            })
            .map((doc) => {
              return {
                label: `${doc.nickname} ${doc?.name ? `(${doc?.name})` : ''} ${
                  doc?.code || ''
                }  `,
                value: doc.id,
              }
            })
        )
      } else {
        setAllKid(
          kids
            // .filter((kid) => {
            //   return kid?.status !== 'off'
            // })
            .map((doc) => {
              return {
                label: `${doc.nickname} ${doc?.name ? `(${doc?.name})` : ''} ${
                  doc?.code || ''
                }  `,
                value: doc.id,
              }
            })
        )
      }
      handleStaff(type, selectedDate)
    } else {
      setCheckboxValue([])
    }
  }, [selectedCourse, selectedDate])

  const handleStaff = async (type, selectedDate) => {
    const today = moment(selectedDate).locale('en').format('dddd')
    const todayDate = moment(selectedDate).format('YYYY-MM-DD')
    const getSkipDayOffStaff = await (
      await getDocs(
        query(
          collection(db, 'Schedules'),
          where('date', '==', todayDate),
          where('isSkipDayOff', '==', true),
          where('staff.branchId', '==', selectedBranch)
        )
      )
    ).docs.map((doc) => ({ ...doc.data(), id: doc.id }))

    if (type !== 'Evaluation' && type !== 'Camp' && type !== 'Extra program') {
      setStaff(
        allStaff.filter((s) => {
          const isStaffWorkingDate = s.workingDay
            .concat(s.workingDayWeekend)
            .includes(today)
          const isSkipDayOffEvent = event.isSkipDayOff && s.id === staff?.id

          const isSkipDayOffDate = getSkipDayOffStaff.find(
            (st) => s.id === st.staffId
          )

          return (
            (s.position === type && isStaffWorkingDate) ||
            (s.position === type && isSkipDayOffEvent) ||
            (s.position === type && isSkipDayOffDate)
          )
        })
      )
      !event.kidId && setValue('kid', '')
    } else {
      setStaff(
        allStaff.filter((s) => {
          const isStaffWorkingDate = s.workingDay
            .concat(s.workingDayWeekend)
            .includes(today)
          const isSkipDayOff = event.isSkipDayOff && s.id === staff?.id
          const isSkipDayOffDate = getSkipDayOffStaff.find(
            (st) => s.id === st.staffId
          )

          return isStaffWorkingDate || isSkipDayOff || isSkipDayOffDate
        })
      )

      !event.kidId && setValue('kid', '')
    }
  }

  useEffect(() => {
    const currentKid =
      kids.length > 0 && kids.find((kid) => kid.id === event?.kidId)

    if (currentKid) {
      setValue('kid', {
        label: `${currentKid.nickname} ${
          currentKid?.name ? `(${currentKid?.name})` : ''
        } ${currentKid?.code || ''}`,
        value: currentKid.id,
      })
    }
    setAllKid(
      kids
        // .filter((kid) => kid?.status !== 'off')
        .map((doc) => {
          return {
            label: `${doc.nickname} ${doc?.name ? `(${doc?.name})` : ''} ${
              doc?.code || ''
            } (${doc.status.toUpperCase()}) `,
            value: doc.id,
          }
        })
    )
  }, [kids, event])

  useEffect(() => {
    if (
      startTime &&
      startTime !== '' &&
      selectedCourse &&
      selectedCourse !== ''
    ) {
      const offsetTime =
        (selectedCourse?.value?.hours || event?.program?.hours) * 60
      const timeSplit = startTime?.split('.')
      let hours = Number(timeSplit[0])
      let minutes = Number(timeSplit[1])
      let date = new Date().setHours(hours, minutes)
      const endTime = moment(date).add(offsetTime, 'm').format('HH.mm')
      setValue('timeEnd', endTime)
      clearErrors('timeEnd')
    }
  }, [startTime, selectedCourse])

  const Time = useMemo(() => {
    let isCamp =
      selectedCourse?.value?.type === 'Camp' || selectedCourse?.type === 'Camp'
    const isWeekend = new Date(selectedDate).getDay() % 6 === 0
    const currentStaff = selectedStaff
      ? staffs.find((s) => s.id === selectedStaff)
      : staff
    const {
      startTimeWeekend,
      startTimeWorkday,
      endTimeWeekend,
      endTimeWorkday,
    } = currentStaff
    let start = isWeekend || isCamp ? startTimeWeekend : startTimeWorkday
    let end = isWeekend ? endTimeWeekend : endTimeWorkday

    return [...Array(48)]
      .map((e, i) => {
        return (
          (i / 2 < 10 ? '0' : '') +
          (i / 2 - ((i / 2) % 1)) +
          ((i / 2) % 1 !== 0 ? '.30' : '.00')
        )
      })
      .filter(
        (time) =>
          Number(time) >= Number(start.replace(':', '.')) &&
          Number(time) <= Number(end.replace(':', '.'))
      )
  }, [selectedCourse, staff, selectedDate, selectedStaff])

  const addCourseForm = [
    {
      label: 'Staff Name',
      name: 'staffId',
      type: 'dropdown',
      options:
        staffs.map((item) => ({
          value: item.id,
          data: item,
          label: `${item.nickname} (${item.fullname})`,
        })) || [],
      placeholder: '',
      defaultValue: staff?.id,
      required: true,
      disabled: !event?.name || event?.isTaked || event.event === 'offday',
    },
  ]

  const addKidForm = [
    {
      label: 'Start Time',
      name: 'timeStart',
      type: 'dropdown',
      options: Time?.filter(
        (_, i) => i < Time.length - selectedCourse?.value?.hours * 2
      ),
      placeholder: '',
      defaultValue: event?.timeStart?.replace('.', ':') || event?.currenTime,
      required: true,
      readonly: true,
      disabled: !selectedCourse || !selectedKid || event?.isTaked,
    },
    {
      label: 'End Time',
      name: 'timeEnd',
      type: 'dropdown',
      options: Time,
      defaultValue: event?.timeEnd?.replace('.', ':'),
      required: true,
      disabled: true,
    },
  ]

  const removeProgram = async (program, kidId) => {
    setLoading(false)

    // const currentProgram =
    //   kidCourses && kidCourses.find((c) => c.id === program.id)
    // const noPayment =
    //   _.isEmpty(currentProgram?.payment) || !currentProgram?.payment

    try {
      await unScheduleById({ scheduleId: event.id })
      // if (noPayment) {
      //   await deleteKidProgram(program, kidId)
      // } else {

      // await updateSchedule({
      //   scheduleId: event.id,
      //   data: {
      //     kidId,
      //     programId: program.id,
      //     date: event.date,
      //     id: event.id,
      //     isTaked: false,
      //     staffId: '',
      //     staff: null,
      //     timeStart: '',
      //     timeEnd: '',
      //     cancelLog: {
      //       date: event.date,
      //       staffId: event.staffId,
      //       staff: event.staff,
      //       timeEnd: event.timeEnd,
      //       timeStart: event.timeStart,
      //       createdAt: new Date(),
      //       createdBy: user.uid,
      //     },
      //     createdAt: new Date(),
      //     createdBy: user.uid,
      //     updatedAt: new Date(),
      //     updatedBy: user.uid,
      //     action: 'cancelSchedule',
      //   },
      // })
      // }

      toast({
        position: 'top',
        title: 'Update Program',
        description: 'Program has been removed.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      onClose()
    } catch (e) {
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function removeSchedule() {
    onCloseDialog()
    setLoading(true)
    try {
      const id = event?.id

      if (typeof id === 'string') {
        if (event?.amount === 1) {
          // remove schedules & program
          removeProgram(
            { schedules: [{ id: event.id }], id: event?.programId },
            event.kidId
          )
        } else if (event?.amount > 1) {
          let data = event
          if (typeof event.kidId === 'string') {
            data = {
              ...event,
              isTaked: false,
              kidId: [event.kidId],
              kid: [event.kid],
              programId: [event.programId],
              programName: `${event?.program.courseName} ${event?.program.choice} (${event?.program.amount} ${event?.program.unit})`,
            }
          }
          const res = await removeProgramMaynyKid(data)
          setResult(res?.data)
          setLoading(false)

          // toast({
          //   position: 'top',
          //   title: 'Update Schedule',
          //   description: 'Schedule has been updated.',
          //   status: 'success',
          //   duration: 3000,
          //   isClosable: true,
          // })
        } else {
          //  remove day off or holiday
          deleteSchedule(id)
          toast({
            position: 'top',
            title: 'Update Schedule',
            description: 'Schedule has been updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          onClose()
        }
      } else {
        const batch = writeBatch(db)
        batch.set(doc(collection(db, 'Schedules')), {
          date: moment(date).format('YYYY-MM-DD'),
          isTaked: false,
          staffId: staff?.id,
          staff: staff,
          timeStart: '',
          timeEnd: '',
          isAllDay: false,
          isSkipDayOff: true,
          createdAt: new Date(),
        })

        await batch.commit()
        toast({
          position: 'top',
          title: 'Update Schedule',
          description: 'Schedule has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function submit(data) {
    setLoading(true)

    try {
      // แก้ลงเวลา//
      const { date, timeStart, timeEnd, staffId, note, isTaked, course } = data
      const staff = allStaff.find((staff) => staff.id === data?.staffId)
      const kid = kids.find((kid) => kid.id === data?.kid?.value)

      if (event?.name) {
        // if (res?.data?.isAvailable || isTaked) {
        if (typeof event?.kidId === 'object') {
          const ids = event?.id?.split(',')
          const promises = ids.map(async (id, index) => {
            const action =
              !event.isTaked && isTaked ? 'completedSchedule' : 'updateSchedule'

            return await updateSchedule({
              scheduleId: id,
              data: {
                note: note,
                timeStart: timeStart,
                timeEnd: timeEnd,
                staffId: staffId,
                isTaked: isTaked,
                staff,
                date: moment(date).format('YYYY-MM-DD'),
                action,
                kidId: event?.kidId[index],
                programId: event?.programId[index],
                updatedAt: new Date(),
                updatedBy: user.uid,
              },
            })
          })

          await Promise.all(promises)

          toast({
            position: 'top',
            title: 'Update Schedule',
            description: 'Schedule has been updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          onClose()
        } else {
          const res = await checkUpdateAvailable({
            staffId,
            date: moment(date).format('YYYY-MM-DD'),
            timeStart,
            timeEnd,
            kidId: kid?.id || event.kid[0].id,
            programName: course?.label,
            id: event?.id,
            program: event.program,
          })
          if (res?.data?.isAvailable) {
            const action =
              !event.isTaked && isTaked ? 'completedSchedule' : 'updateSchedule'

            await updateSchedule({
              scheduleId: event?.id,
              data: {
                note: note,
                timeStart: timeStart,
                timeEnd: timeEnd,
                staffId: staffId,
                isTaked: isTaked,
                staff,
                date: moment(date).format('YYYY-MM-DD'),
                programId: event.programId,
                action,
                kidId: kid.id,
                updatedAt: new Date(),
                updatedBy: user.uid,
              },
            })

            toast({
              position: 'top',
              title: 'Update Schedule',
              description: 'Schedule has been updated.',
              status: 'success',
              duration: 3000,
              isClosable: true,
            })

            setLoading(false)
            onClose()
          } else {
            setLoading(false)
            onClose()
            throw new Error(res.data?.message)
          }
        }
      } else {
        const res = await createSchedule(
          JSON.stringify({
            ...data,
            staffId,
            staff,
            date: moment(date).format('YYYY-MM-DD'),
            timeStart,
            timeEnd,
            kidId: kid?.id,
            kid,
            programName: course.label,
            checkboxValue,
            action: 'createSchedule',
          })
        )
        if (res?.data?.isAvailable) {
          if (res.data.result) {
            setResult(res.data.result)
          } else {
            onClose()
            toast({
              position: 'top',
              title: 'Add Schedule',
              description: 'Schedule has been added.',
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
          }
          setLoading(false)
        } else {
          onClose()
          setLoading(false)

          throw new Error(res.data?.message)
        }
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Add Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        onClose={onCloseDialog}
        isOpen={isOpenDialog}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete this schedule
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button isDisabled={isLoading} onClick={onCloseDialog}>
              No
            </Button>
            <Button
              isDisabled={isLoading}
              colorScheme='red'
              ml={3}
              onClick={() => removeSchedule()}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: fullscreen ? 'full' : '4xl', lg: '4xl' }}
        closeOnOverlayClick={false}
        motionPreset='none'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalBody p={10}>
            {result && result?.length > 0 ? (
              <TableContainer>
                <Table size='sm'>
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Time</Th>
                      <Th>Staff</Th>
                      <Th>Kid</Th>
                      <Th>Result</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {result?.map(
                      (
                        {
                          date,
                          timeEnd,
                          timeStart,
                          message,
                          isAvailable,
                          staff,
                          kid,
                        },
                        index
                      ) => (
                        <Tr key={index} spacing={10}>
                          <Td>
                            {new Date(date).toLocaleDateString('th-TH', {
                              weekday: 'long',
                            })}{' '}
                            {moment(date).format('DD/MM/YYYY')}
                          </Td>
                          <Td>{`${timeStart}-${timeEnd} `}</Td>
                          <Td>{`${staff.nickname} (${staff.fullname})`}</Td>
                          <Td>{`${kid?.nickname} (${kid?.code})`}</Td>
                          <Td color={isAvailable ? 'green' : 'red'}>
                            {message}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Box>
                <HStack w='100%' justifyContent='space-between' my={2}>
                  <Text fontSize={'xl'} color='brand'>
                    Take Course
                  </Text>
                  {!event?.isTaked && event?.name && role !== 'Staff' && (
                    <Button
                      variant='outline'
                      colorScheme='red'
                      isDisabled={isLoading}
                      onClick={onOpenDialog}
                    >
                      Delete Schedule
                    </Button>
                  )}
                </HStack>
                <Box
                  borderWidth='1px'
                  borderColor='brand'
                  borderRadius='10px'
                  mt={1}
                  p={5}
                >
                  <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    spacingX={{ base: 0, md: 10 }}
                  >
                    <FormInputs
                      errors={errors}
                      control={control}
                      forms={addCourseForm || []}
                      data={event}
                    />
                    <FormControl
                      my={3}
                      id='date'
                      name='note'
                      w={'100%'}
                      isRequired
                    >
                      <FormLabel display='flex' flexDirection='row' w='full'>
                        <Text>
                          {watch('date')
                            ? `${new Date(watch('date')).toLocaleDateString(
                                'th-TH',
                                {
                                  weekday: 'long',
                                }
                              )}
                              ${moment(watch('date')).format('DD/MM/YYYY')}`
                            : ''}
                        </Text>
                      </FormLabel>
                      <Controller
                        name='date'
                        control={control}
                        defaultValue={moment(date).format('yyyy-MM-DD')}
                        isReadonly={event.event === 'offday'}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Input
                              type='date'
                              value={value}
                              onChange={onChange}
                            />
                          )
                        }}
                      />
                    </FormControl>
                  </SimpleGrid>
                </Box>
                {event.event !== 'offday' && (
                  <Box
                    borderWidth='1px'
                    borderColor='brand'
                    borderRadius='10px'
                    mt={10}
                    p={5}
                  >
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacingX={{ base: 0, md: 10 }}
                    >
                      <FormControl
                        my={3}
                        id='course'
                        name='course'
                        isInvalid={true}
                        w={'100%'}
                      >
                        <FormLabel>Program</FormLabel>
                        <Controller
                          name='course'
                          control={control}
                          rules={{
                            required: 'Please select a course',
                          }}
                          defaultValue={
                            event?.program
                              ? {
                                  value: event?.program,
                                  label:
                                    event?.program?.label ||
                                    `${event?.program.courseName} ${event?.program.choice} (${event?.program.amount} ${event?.program.unit})`,
                                }
                              : null
                          }
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                id='program'
                                name='program'
                                placeholder='Select course'
                                options={courseOptions}
                                formatGroupLabel={formatGroupLabel}
                                onChange={onChange}
                                value={value}
                                isDisabled={event?.name}
                              />
                            )
                          }}
                        />
                        <FormErrorMessage>
                          {errors.course && errors.course.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        my={3}
                        id='course'
                        name='course'
                        isInvalid={true}
                        w={'100%'}
                      >
                        <FormLabel>KID</FormLabel>
                        {event?.kid?.length > 1 ? (
                          event.kid?.map((k, i) => {
                            const completed =
                              completedManyKids &&
                              completedManyKids?.find(
                                (c) => c?.kidCode === k.code
                              )

                            return (
                              <Stack py={2} key={i}>
                                <Text
                                  color={completed ? 'green' : 'black'}
                                  key={i}
                                  fontSize={16}
                                >
                                  {`${k.nickname} ${
                                    k.code ? ` (${k.code}) ` : ''
                                  }  ${
                                    completed && event?.kidsCompleted[i]
                                      ? ` ครั้งที่ (${
                                          completed?.noOfCompleted
                                        }/${
                                          event?.campAmount
                                            ? event?.campAmount[i]
                                            : event?.program.amount
                                        })`
                                      : ''
                                  } `}
                                  {completed?.noOfCompleted /
                                    event.program.amount >=
                                    0.7 && event.program.amount > 1 ? (
                                    <span
                                      style={{ color: 'red', marginLeft: 2 }}
                                    >
                                      ใกล้ครบแล้ว
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Text>
                                <HStack
                                  alignItems='center'
                                  justifyContent='center'
                                >
                                  <Link to={`/kids/${k?.id}?tab=3`}>
                                    <Button
                                      size='sm'
                                      colorScheme='lime'
                                      variant='outline'
                                      isDisabled={isLoading}
                                    >
                                      To Kid's Program
                                    </Button>
                                  </Link>
                                  <Link to={`/kids/${k?.id}?tab=4`}>
                                    <Button
                                      size='sm'
                                      colorScheme='lime'
                                      variant='outline'
                                      isDisabled={isLoading}
                                    >
                                      To Kid's Payment
                                    </Button>
                                  </Link>
                                </HStack>
                              </Stack>
                            )
                          })
                        ) : (
                          <Controller
                            name='kid'
                            control={control}
                            rules={{
                              required: 'Please select a kid',
                            }}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  id='kid'
                                  name='kid'
                                  placeholder='Select kid'
                                  options={allKid.filter((o) => {
                                    return kids.find(
                                      (item) => item.id === o.value
                                    )
                                  })}
                                  onChange={onChange}
                                  value={value}
                                  isDisabled={event?.name}
                                />
                              )
                            }}
                          />
                        )}
                        <FormErrorMessage>
                          {errors.kid && errors.kid.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormInputs
                        errors={errors}
                        control={control}
                        forms={addKidForm || []}
                        data={event}
                      />
                    </SimpleGrid>
                    {Number(selectedCourse?.value?.amount) > 1 && !event?.name && (
                      <FormControl my={3} id='course' name='course' w={'100%'}>
                        <FormLabel>วันที่ต้องการลงเรียน</FormLabel>
                        <CheckboxGroup colorScheme='lime' value={checkboxValue}>
                          <SimpleGrid columns={4} spacing={2}>
                            {weekday.map((day, index) => (
                              <Checkbox
                                {...getCheckboxProps({
                                  value: index.toString(),
                                })}
                                key={day}
                                isDisabled={!staffWorkingDays.includes(day)}
                              >
                                {day}
                              </Checkbox>
                            ))}
                          </SimpleGrid>
                        </CheckboxGroup>
                      </FormControl>
                    )}
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      spacingX={{ base: 0, md: 10 }}
                    >
                      <FormControl my={3} id='note' name='note' w={'100%'}>
                        <FormLabel>Note</FormLabel>
                        <Controller
                          name='note'
                          control={control}
                          defaultValue={event?.note || ''}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Textarea value={value} onChange={onChange} />
                            )
                          }}
                        />
                      </FormControl>
                      {(role === 'Manager' || role === 'Owner') && (
                        <FormControl
                          my={3}
                          id='isTaked'
                          name='isTaked'
                          isInvalid={true}
                          w={'100%'}
                        >
                          <FormLabel>Completed</FormLabel>
                          <Controller
                            name='isTaked'
                            control={control}
                            defaultValue={
                              event?.kid?.length > 1
                                ? event?.kidsCompleted.reduce((a, e) => {
                                    return a + (e ? 1 : 0)
                                  }, 0) === event?.kidsCompleted.length
                                : event?.isTaked
                            }
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Switch
                                  colorScheme='lime'
                                  size='lg'
                                  isChecked={value}
                                  onChange={onChange}
                                  mr={4}
                                />
                              )
                            }}
                          />
                          {completed && event?.isTaked
                            ? `(${completed}/${event?.program?.amount}) `
                            : ''}
                          {completed / event?.program?.amount >= 0.7 &&
                          event?.program?.amount > 1 ? (
                            <span style={{ color: 'red', marginLeft: 2 }}>
                              จำนวนครั้งใกล้ครบแล้ว
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      )}
                      {typeof event.kidId === 'string' && (
                        <>
                          <div />
                          <HStack alignItems='center' justifyContent='center'>
                            <Link to={`/kids/${event?.kidId}?tab=3`}>
                              <Button
                                size='sm'
                                colorScheme='lime'
                                variant='outline'
                                isDisabled={isLoading}
                              >
                                To Kid's Program
                              </Button>
                            </Link>
                            <Link to={`/kids/${event?.kidId}?tab=4`}>
                              <Button
                                size='sm'
                                colorScheme='lime'
                                variant='outline'
                                isDisabled={isLoading}
                              >
                                To Kid's Payment
                              </Button>
                            </Link>
                          </HStack>
                        </>
                      )}
                    </SimpleGrid>
                  </Box>
                )}
              </Box>
            )}
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button
              mr={3}
              variant='outline'
              onClick={onClose}
              isDisabled={isLoading}
            >
              Close
            </Button>
            {event.event !== 'offday' && !result && role !== 'Staff' && (
              <Button
                colorScheme='lime'
                onClick={handleSubmit(submit)}
                bg='brand'
                isLoading={isLoading}
                loadingText='Updating...'
                isDisabled={
                  (typeof event.kid === 'string' && !selectedKid) || isLoading
                }
              >
                Update
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
